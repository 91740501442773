import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import ApiService from "services/api.service";
import Intro from "components/UI/Membership/Intro/Intro";
import Plans from "components/UI/Membership/Plans/Plans";
import Testimonials from "components/UI/Membership/Testimonials/Testimonials";
import GiftMembership from "components/UI/Membership/GiftMembership/GiftMembership";
import ContactUs from "components/UI/Membership/ContactUs/ContactUs";
import PageMeta from "shared/PageMeta/PageMeta";
import Faq from "pages/InfoPages/FAQ/Faq";
import Loader from "shared/Loader/Loader";

import { onSetUser } from "store/actions/userActions";
import { onGetLibraryItems } from "store/actions/libraryActions";

function MembershipRedesign() {
  const [membership, setMembership] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [testimonials, setTestimonials] = useState();
  const [confirmationFailed, setConfirmationFailed] = useState(false);

  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      const localToken = localStorage.getItem("token");

      if (!localToken && token) {
        try {
          const confirmRes = await ApiService.confirmUser({
            confirmationToken: token,
          });
          localStorage.setItem("token", confirmRes.data.token);

          const userRes = await ApiService.getUser();
          dispatch(onSetUser(userRes.data));

          const params = new URLSearchParams({ page: 1, itemsPerPage: 10 });
          const libraryRes = await ApiService.getLibrary(params);
          dispatch(
            onGetLibraryItems(
              libraryRes.data["hydra:member"],
              libraryRes.data["hydra:totalItems"]
            )
          );
        } catch (err) {
          console.error("Email confirmation failed:", err);
          setConfirmationFailed(true);
        }
      }

      try {
        const membershipRes = await ApiService.getMembershipPage();
        const data = membershipRes.data["hydra:member"]?.[0] || null;
        setMembership(data);
        setTestimonials(data?.testimonialBlocks);
      } catch (e) {
        console.error("Error loading membership page:", e);
      } finally {
        setIsLoading(false);
      }
    };

    init();
  }, [token, dispatch]);

  return (
    <>
      <PageMeta>
        <title>Subscription | Spiracle</title>
        <link rel="canonical" href={window.location.href} />
      </PageMeta>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {confirmationFailed && (
            <div className="error mb-20 container container__big container__big--withPadding">
              There was a problem confirming your email. Please try the link
              again or contact support.
            </div>
          )}

          {membership && <Intro data={membership} />}

          <div className="container container__big container__big--withPadding">
            {membership && <Plans data={membership} />}
            {testimonials?.length > 0 && (
              <Testimonials
                data={testimonials}
                title={membership.testimonialsTitle}
                subtitle={membership.testimonialsSubtitle}
              />
            )}
            {membership && <GiftMembership data={membership} />}
            <Faq notPage />
            <ContactUs />
          </div>
        </>
      )}
    </>
  );
}

export default MembershipRedesign;
