import * as bookActionTypes from '../../store/actions/bookActions';
import * as cartActionTypes from '../../store/actions/cartActions'
import * as libraryActionTypes from '../../store/actions/libraryActions';
import * as userActionTypes from '../../store/actions/userActions';

import React, { Component } from 'react';

import ApiService from 'services/api.service';
import ArticleItem from '../../components/UI/ArticleItem/ArticleItem'
import AudioBookListItem from '../../components/UI/AudioBook/AudioBookListItem';
import Auth from '../../containers/Auth/Auth';
import EmptyOrdersPage from 'shared/Pages/EmptyOrdersPage/EmptyOrdersPage';
import { EmptyPage } from "../../pages/AudioBooks/style";
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';
import ShoppingBagItems from "components/UI/ShoppingBag/ShoppingBagItems";
import SkeletonOrderItem from 'components/UI/Skeleton/SkeletonOrderItem/SkeletonOrderItem';
import SkeletonSearch from "components/UI/Skeleton/SkeletonSearch/SkeletonSearch";
import SpiracleLogo from '../../images/Spiracle-logo.svg';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { debounce } from '../../shared/Functions/Debounce';
import { withRouter } from 'react-router-dom';
import Banner from 'components/UI/Banner/Banner';
import BannerPaymentFailed from 'components/UI/BannerPaymentFailed/BannerPaymentFailed';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            searchMenuOpen: false,
            libraryMenuOpen: false,
            shopMenuOpen: false,
            userMenuOpen: false,
            openAuth: false,
            settingsOpen: false,
            hasVScroll: false,
            showHeader: true,
            categoryMenuList: [],
            murmurationsMenuList: [],
            linksMenuList: [],
            isUser: false,
            page: 1,
            itemsPerPage: 10,
            isLoadMoreLibrary: false,
            searchKeyword: '',
            searchResults: {},
            isLoadingSearch: false,
            searchMenuListGroupOne: [],
            searchMenuListGroupTwo: [],
        };

        this.doc = document.documentElement;
        this.w = window;
        this.prevScroll = this.w.scrollY || this.doc.scrollTop;
        this.curScroll = null;
        this.direction = 0;
        this.prevDirection = 0;
        this.apiCallSearch = debounce(this.apiCall, 400);
        this.searchInput = React.createRef();
    }

    componentDidMount() {
        this.setState({
            isUser: localStorage.getItem('token')
        }, () => {
            ApiService.menuEntries().then(
                r => {
                    this.setState({
                        categoryMenuList: r.data['hydra:member'].filter(cat => cat.category === 'Products'),
                        murmurationsMenuList: r.data['hydra:member'].filter(cat => cat.category === 'Murmurations'),
                        linksMenuList: r.data['hydra:member'].filter(cat => cat.category === 'Links')
                    })

                    ApiService.searchMenuList().then(
                        r => {
                            this.setState({
                                searchMenuListGroupOne: r.data['hydra:member'].filter(item => item.menuGroup === 'Group 1'),
                                searchMenuListGroupTwo: r.data['hydra:member'].filter(item => item.menuGroup === 'Group 2'),
                            })
                        }
                    )
                }
            )
            var hasVScroll = document.body.scrollHeight > window.innerWidth;
            this.setState({
                hasVScroll: hasVScroll,
            });
    
            window.addEventListener('scroll', this.checkScroll);
        })

        /**
         * click outside menus
         */
        document.addEventListener('click', e => {
            const el = e.target.classList;

            if(!el.contains('icon-hamburger') && !el.contains('icon-search') && !el.contains('icon-library') && !el.contains('icon-user') && !el.contains('icon-account') && !el.contains('icon-shopping-bag')) {
                if (!e.target.closest('.c-navigation') && window.innerWidth > 768) {
                    this.handleCloseLoginModal()
                }
            }

            if (e.target.id === 'open-auth') {
                this.handleUserOpen();
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            const token = localStorage.getItem('token');
            const params = new URLSearchParams();

            params.append('page', this.state.page);
            params.append('itemsPerPage', this.state.itemsPerPage);

            if (token && this.state.isLoadMoreLibrary) {
                ApiService.getLibrary(params).then(
                    library => {
                        this.props.onLoadMoreLibraryItems(library.data['hydra:member'], library.data['hydra:totalItems'])
                        this.setState({
                            isLoadMoreLibrary: false,
                        })
                    }
                )
            }
        }

        var hasVScroll = document.body.scrollHeight > window.innerWidth;

        if (this.props.location.pathname !== prevProps.location.pathname) {
            // menuOpen
            this.setState({
                hasVScroll: hasVScroll,
                searchKeyword: '',
                searchResults: {},
            });
        }
    }

    /**
     * api call
     */

    apiCall = (params) => {
        ApiService.quickSearch(params).then(
            r => {
                this.setState({
                    isLoadingSearch: false,
                    searchResults: r.data['hydra:member'][0]
                })
            }
        )
    }

    /**
     * handle quick search
     */
    handleSearch = (e) => {
        this.setState({
            searchKeyword: e.target.value,
            isLoadingSearch: true,
        }, () => {
                const params = new URLSearchParams();
                params.append('keyword', this.state.searchKeyword);

                if (this.state.searchKeyword.length > 2) {
                    // call api
                    this.apiCallSearch(params)
                }
            })
    }

    /**
     * reset keyword
     */
    handleResetSearchKeyword = () => {
        this.setState({ searchKeyword: '' });

        setTimeout(() => {
            this.setState({ searchMenuOpen: true }) // search menu remains open
        },0)
    }

    /**
     * handle show all books & articles
     */
    handleShowAll = (type) => {
        if (type === 'books') {
            this.props.history.push({
                pathname: '/search-books',
                search: `/?keyword=${this.state.searchKeyword}&page=1`,
                state: { fromQuick: true }
            })
        } else {
            this.props.history.push({
                pathname: '/search-articles',
                search: `?keyword=${this.state.searchKeyword}&page=1&search%5B%5D=articles&search%5B%5D=interviews&search%5B%5D=discussions`,
                state: { fromQuick: true }
            })
        }
        this.setState({ searchKeyword: '', searchResults: {} })
    }

    /**
     * load more books in library
     */
    handleLoadMoreLibrary = (e) => {
        const scrollTop = e.target.scrollTop;
        const offsetHeight = e.target.offsetHeight;
        const scrollHeight = e.target.scrollHeight;

        if (scrollTop + offsetHeight > scrollHeight - 150 && (this.state.page * this.state.itemsPerPage < this.props.libraryTotalItems)) {
            if (!this.state.isLoadMoreLibrary) {
                this.setState({
                    page: this.state.page + 1,
                    isLoadMoreLibrary: true
                })
            }
        }
    }

    /**
     * check direction of scroll
     */
    checkScroll = () => {
        /*
         ** Find the direction of scroll
         ** 0 - initial, 1 - up, 2 - down
         */

        this.curScroll = this.w.scrollY || this.doc.scrollTop;
        if (this.curScroll > this.prevScroll + 20) {            
            //scrolled up
            this.direction = 2;
            this.setState({
                showHeader: false,
            });
        } else if (this.curScroll < this.prevScroll) {
            //scrolled down
            this.direction = 1;
            this.setState({
                showHeader: true,
            });
        }

        this.prevScroll = this.curScroll;
    };

    showHeaderWhenAuthOpen = () => {
        if(!this.state.showHeader) {            
            this.setState({
                showHeader: true,
            });
        }        
    }

    /**
     * Open burger menu
     */
    handleMenuOpen = () => {
        this.setState(
            {
                ...this.state,
                menuOpen: !this.state.menuOpen,
                searchMenuOpen: false,
                libraryMenuOpen: false,
                shopMenuOpen: false,
                userMenuOpen: false,
                openAuth: false,           
            },
            () => {
                if (window.innerWidth < 768 && this.state.menuOpen) {
                    document.body.classList.add('noScroll');
                } else {
                    document.body.classList.remove('noScroll');
                }
            }
        );
    };

    /**
     * Open search menu on right hand side
     */
    handleSearchMenuOpen = () => {
        this.setState(
            {
                ...this.state,
                searchMenuOpen: !this.state.searchMenuOpen,
                libraryMenuOpen: false,
                shopMenuOpen: false,
                userMenuOpen: false,
                menuOpen: false,
                openAuth: false,
            },
            () => {
                if (window.innerWidth < 768 && this.state.searchMenuOpen) {
                    document.body.classList.add('noScroll');
                } else {
                    document.body.classList.remove('noScroll');
                }

                if (this.state.searchMenuOpen) {
                    if (this.searchInput.current) {
                        this.searchInput.current.focus()
                    }
                }
            }
        );
    };

    /**
     * Open library menu on right hand side
     */
    handleLibraryOpen = () => {
        this.setState(
            {
                ...this.state,
                libraryMenuOpen: !this.state.libraryMenuOpen,
                searchMenuOpen: false,
                shopMenuOpen: false,
                userMenuOpen: false,
                menuOpen: false,
                openAuth: false,
            },
            () => {
                if (window.innerWidth < 768 && this.state.libraryMenuOpen) {
                    document.body.classList.add('noScroll');
                } else {
                    document.body.classList.remove('noScroll');
                }
            }
        );
    };

    /**
     * Open user menu on right hand side
     */
    handleUserOpen = () => {
        if (this.props.user) {
            this.setState(
                {
                    ...this.state,
                    userMenuOpen: !this.state.userMenuOpen,
                    libraryMenuOpen: false,
                    searchMenuOpen: false,
                    shopMenuOpen: false,
                    menuOpen: false,
                    openAuth: false,             
                },
                () => {
                    if (window.innerWidth < 768 && this.state.userMenuOpen) {
                        document.body.classList.add('noScroll');
                    } else {
                        document.body.classList.remove('noScroll');
                    }
                }
            );
        } else {
            this.showHeaderWhenAuthOpen();
            this.setState({
                openAuth: !this.state.openAuth,                
            });
        }
    };

    /**
     * Open shop on right hand side
     */
    handleShopOpen = () => {
        if (this.state.userMenuOpen || this.state.libraryMenuOpen || this.state.searchMenuOpen) {
            this.setState(
                {
                    shopMenuOpen: !this.state.shopMenuOpen,
                },
                () => {
                    setTimeout(() => {
                        this.setState({
                            ...this.state,
                            userMenuOpen: false,
                            libraryMenuOpen: false,
                            searchMenuOpen: false,
                            menuOpen: false,
                            openAuth: false,
                        });
                    }, 300);
                }
            );
        } else {
            this.setState(
                {
                    ...this.state,
                    shopMenuOpen: !this.state.shopMenuOpen,
                    userMenuOpen: false,
                    libraryMenuOpen: false,
                    searchMenuOpen: false,
                    menuOpen: false,
                },
                () => {
                    if (window.innerWidth < 768 && this.state.shopMenuOpen) {
                        document.body.classList.add('noScroll');
                    } else {
                        document.body.classList.remove('noScroll');
                    }
                }
            );
        }
    };

    /**
     * Close login modal
     */
    handleCloseLoginModal = () => {
        this.setState({
            openAuth: false,
            shopMenuOpen: false,
            userMenuOpen: false,
            libraryMenuOpen: false,
            searchMenuOpen: false,
            menuOpen: false,
        });
    };

    /**
     * Close all modals
     */
     handleCloseModals = () => {
        this.setState({
            shopMenuOpen: false,
            libraryMenuOpen: false,
            searchMenuOpen: false,
            menuOpen: false,
        });
    };

    /**
     * Handle logout
     */
    handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('tokenRefreshedDate');
        this.props.onLogout();
        this.handleCloseLoginModal();
        this.props.history.push('/');
        this.props.onClearCart();
        this.props.onRemoveLibrary();
        this.props.onRemoveBook();
        cookie.remove('cartId');
        document.body.classList.remove('player-visible');
    };

    handleScrollToTop = () => {
        window.scrollTo(0,0);
    }

    render() {
        let menuCategories
        let menuCategoriesDesktop
        let menuMurmurations
        let menuMurmurationsDesktop
        let menuLinkList
        let menuLinkListHeader
        let libraryItems
        let searchBooks
        let searchArticles
        let searchMenuListGroupOne
        let searchMenuListGroupTwo

        if (this.state.categoryMenuList) {
            menuCategories = this.state.categoryMenuList.map((category, index) => (
                <Link id={`gtm-header-category-${category.title.toString().toLowerCase().replace(/ /g, '-')}`} key={`category-${index}`} onClick={() => {this.handleMenuOpen(); this.handleScrollToTop()}} 
                    className='c-btn c-btn__ghost mr-7 mb-13 font-22 font-secondary font-italic' 
                    to={{
                        pathname: category.url.includes('?') ? category.url.split('?')[0] : category.url,
                        search: category.url.includes('?') ? category.url.split('?')[1] : '',
                        state: { fromQuick: true }
                    }}
                >
                    {category.title}
                </Link>
            ))
            menuCategoriesDesktop = this.state.categoryMenuList.map((category, index) => (
                <li key={`category-${index}`}>
                    <Link 
                        id={`gtm-header-category-${category.title.toString().toLowerCase().replace(/ /g, '-')}`}
                        to={{
                            pathname: category.url.includes('?') ? category.url.split('?')[0] : category.url,
                            search: category.url.includes('?') ? category.url.split('?')[1] : '',
                            state: { fromQuick: true }
                        }}
                        className='animated-underline'
                    >
                        {category.title}
                    </Link>
                </li>
            ))
        }

        if (this.state.murmurationsMenuList) {
            menuMurmurations = this.state.murmurationsMenuList.map((murmuration, index) => (
                <li key={`murmuration-${index}`}>
                    <Link 
                        id={`gtm-header-murmuration-${murmuration.title.toString().toLowerCase().replace(/ /g, '-')}`}
                        key={`murmuration-${index}`} onClick={() => {this.handleMenuOpen(); this.handleScrollToTop()}} 
                        className={`c-btn c-btn__ghost mr-7 mb-13 font-22 font-secondary font-italic 
                            ${this.props.location.pathname.includes('/murmurations') && this.props.location.search === '?_page=1&itemsPerPage=5&search%5B%5D=articles&search%5B%5D=interviews&search%5B%5D=discussions&sort=publishedAt' && index === 0 && 'c-btn__ghost--active'}
                        `} 
                        to={{
                            pathname: murmuration.url.includes('?') ? murmuration.url.split('?')[0] : murmuration.url,
                            search: murmuration.url.includes('?') ? murmuration.url.split('?')[1] : '',
                            state: { fromQuick: true }
                        }}
                    >
                        {murmuration.title}
                    </Link>
                </li>
            ))
            menuMurmurationsDesktop = this.state.murmurationsMenuList.map((murmuration, index) => (
                <li key={`murmurationDesktop-${index}`}>
                    <Link 
                        id={`gtm-header-murmuration-${murmuration.title.toString().toLowerCase().replace(/ /g, '-')}`}
                        to={{
                            pathname: murmuration.url.includes('?') ? murmuration.url.split('?')[0] : murmuration.url,
                            search: murmuration.url.includes('?') ? murmuration.url.split('?')[1] : '',
                            state: { fromQuick: true }
                        }}
                        className='animated-underline'
                    >
                        {murmuration.title}
                    </Link>
                </li>
            ))
        }

        if (this.state.searchMenuListGroupOne) {
            searchMenuListGroupOne = this.state.searchMenuListGroupOne.map((item, index) => (
                <Link 
                    id={`gtm-header-search-menu-${item.title.toString().toLowerCase().replace(/ /g, '-')}`}
                    key={`group-one-item-${index}`}
                    className={`c-btn c-btn__ghost mr-7 mb-13 font-22 font-secondary ${item.style === 'Italic' && 'font-italic'}`}
                    to={{
                        pathname: item.url.includes('?') ? item.url.split('?')[0] : item.url,
                        search: item.url.includes('?') ? item.url.split('?')[1] : '',
                        state: { fromQuick: true }
                    }} 
                    onClick={this.handleSearchMenuOpen}>
                    {item.title}
                </Link>
            ))
        }

        if (this.state.searchMenuListGroupTwo) {
            searchMenuListGroupTwo = this.state.searchMenuListGroupTwo.map((item, index) => (
                <Link 
                    id={`gtm-header-search-menu-${item.title.toString().toLowerCase().replace(/ /g, '-')}`}
                    key={`group-two-item-${index}`}
                    className={`c-btn c-btn__ghost mr-7 mt-13 font-22 font-secondary ${item.style === 'Italic' && 'font-italic'}`} 
                    to={{ 
                        pathname: item.url.includes('?') ? item.url.split('?')[0] : item.url,
                        search: item.url.includes('?') ? item.url.split('?')[1] : '',
                        state: { fromQuick: true }
                    }}
                    onClick={() => this.setState({ searchMenuOpen: false })}
                    >
                    {item.title}
                </Link>
            ))
        }

        if (this.state.linksMenuList) {
            menuLinkList = this.state.linksMenuList.map((link, index) => (
                <li key={`link-${index}`}>
                    <Link 
                        id={`gtm-header-menu-link-${link.title.toString().toLowerCase().replace(/ /g, '-')}`}
                        className='c-btn c-btn__ghost mr-7 mb-13 font-22 font-secondary font-italic' 
                        to={{ 
                            pathname: link.url.includes('?') ? link.url.split('?')[0] : link.url,
                            search: link.url.includes('?') ? link.url.split('?')[1] : '',
                            state: { fromQuick: true }
                        }}  
                        onClick={() => {this.handleMenuOpen(); this.handleScrollToTop()}}>
                        {link.title}
                    </Link>
                </li>
            ))
            menuLinkListHeader = this.state.linksMenuList.map((link, index) => (
                <li key={`link-${index}`}>
                    <Link 
                        id={`gtm-header-menu-link-header-${link.title.toString().toLowerCase().replace(/ /g, '-')}`}
                        className='animated-underline' 
                        to={{ 
                            pathname: link.url.includes('?') ? link.url.split('?')[0] : link.url,
                            search: link.url.includes('?') ? link.url.split('?')[1] : '',
                            state: { fromQuick: true }
                        }}  
                        onClick={() => {this.handleMenuOpen(); this.handleScrollToTop()}}>
                        {link.title}
                    </Link>
                </li>
            ))
        }

        if (this.props.library) {
            libraryItems = this.props.library.map((book, index) => {
                let bookInfo                    
                if (book.audioBook) {
                    bookInfo = book.audioBook
                } else if (book.eBook) {
                    bookInfo = book.eBook
                }
                
                if (bookInfo) {
                    return (
                        <AudioBookListItem 
                            key={`book-${index}`}      
                            title={bookInfo.title}
                            authors={bookInfo.authors && bookInfo.authors['hydra:member'] ? bookInfo.authors['hydra:member'] : bookInfo.authors}
                            imgSrc={bookInfo.cover}
                            imgAlt={bookInfo.coverAlt}
                            link={`/${book.audioBook ? 'audiobooks' : 'ebooks'}/${bookInfo.slug}`}
                            eBook={bookInfo.eBook}
                            isEdition={bookInfo.edition}
                            bookBgColor={bookInfo.bookBackgroundColour}
                            containerBgColor={bookInfo.containerBackgroundColour}
                            textColor={bookInfo.textColour}
                            type={book.audioBook ? 'audiobook' : 'ebooks'}
                            horizontal
                            mobileOnly
                            hidePrice
                            className="mt-12"
                            onClick={this.handleLibraryOpen}
                            availableAsCard={bookInfo.availableAsCard}
                        />  
                    );
                }

                return true
            })
        }

        if (this.state.searchResults.booksTotal) {
            searchBooks = this.state.searchResults.books.slice(0,4).map((book, index) => (
                <Grid item xs={6} key={`audioBook-${index}`}>
                    <AudioBookListItem
                        title={book.title}
                        imgSrc={book.cover}
                        imgAlt={book.coverAlt}
                        price={book.price}
                        subscriptionPrice={book.subscriptionPrice}
                        authors={book.authors}
                        type={book.entityType}
                        audioBook={book.audioBook}
                        eBook={book.eBook}
                        link={`/${book.entityType}/${book.slug}`}
                        mobileOnly
                        availableAsCard={book.availableAsCard}
                    />
                </Grid>
            ))
        }

        if (this.state.searchResults.articlesTotal) {
            searchArticles = this.state.searchResults.articles.slice(0,2).map((item, index) => (
                <ArticleItem 
                    key={`item-${index}`}
                    image={item.image}
                    alt={item.altText}
                    author={item.author ? `${item.author.firstName} ${item.author.lastName}` : null}
                    category={item.category ? item.category.name : null}
                    title={item.title}
                    slug={item.slug}
                    type={item.type ? item.type.title : null}
                    articleType={item.entityType}
                    //featured={item.featured}
                    summary={item.summary}
                    mobileOnly
                />              
            ))
        } 

        return (
            <>
                <BannerPaymentFailed className={`${this.state.showHeader ? 'show' : 'hide'}`} />
                <Banner className={`${this.state.showHeader ? 'show' : 'hide'}`} />
                <header className='c-header h-flex h-flex-between'>
                    <div className={`c-header__wrapper h-flex h-flex-between h-flex-middle ${this.state.showHeader ? 'show' : 'hide'}`}>
                        {/* BURGER MENU */}
                        <div className={`c-header__logo-wrapper h-flex h-flex-between h-flex-align-end ${this.state.menuOpen ? 'menu-open' : ''}`}>
                            <div onClick={() => this.handleMenuOpen()} className='c-header__burger-menu'>
                                <i className='icon-hamburger font-black font-24'></i>
                            </div>

                            <Link id='gtm-header-spiracle-logo' to='/' className='c-header__logo h-flex h-flex-align-end' onClick={this.handleScrollToTop}>
                                <img src={SpiracleLogo} alt='spiracle-logo' />
                                {/* <span>Spiracle</span> */}
                            </Link>
                            <span onClick={() => this.handleShopOpen()} className='c-header__logo-wrapper--shop h-flex h-flex-align-end'>
                                <i className='icon-shopping-bag font-24 font-black'>
                                    {this.props.cart.length > 0 ? <span className="font-12 font-secondary">{this.props.cart.length}</span> : null}
                                </i>
                            </span>
                        </div>
                        {/* *************** */}

                        {/* RIGHT HAND NAVIGATION */}
                        <nav className='c-header__right h-flex h-flex-align-end'>
                            <ul className='c-header__right--list h-flex h-flex-center h-flex-middle'>
                                <li className='c-header__right--list--item c-header__right--list--audiobooks c-header__right--list--desktop-only'>
                                    <div className='c-header__right--item font-secondary font-uppercase letter-spacing-15 font-14'>Audiobooks</div>
                                    <ul className='c-header__right--sub-list'>
                                        <li>
                                            <Link id='gtm-header-browse' to='/browse' className='animated-underline'>Browse all</Link>
                                        </li>
                                        <li>
                                            <Link id='gtm-header-editions' to='/editions?_page=1&itemsPerPage=5' className='animated-underline'>Spiracle <div className='font-italic' style={{display: 'inline'}}>editions</div></Link>
                                        </li>
                                        <li>
                                            <Link id='gtm-header-collection' to='/audiobooks?page=1&itemsPerPage=12' className='animated-underline'>Collection</Link>
                                        </li>
                                        <li>
                                            <Link id='gtm-header-current-highlights' to='/subscriber-selection?page=1&itemsPerPage=12' className='animated-underline'>Current <div className='font-italic' style={{display: 'inline'}}>Highlights</div></Link>
                                        </li>
                                        {menuCategoriesDesktop}
                                    </ul>
                                </li>
                                <li className='c-header__right--list--item c-header__right--list--desktop-only'>
                                    <div className='c-header__right--item font-secondary font-uppercase letter-spacing-15 font-14'>Murmurations</div>
                                    <ul className='c-header__right--sub-list'>
                                        {menuMurmurationsDesktop}
                                    </ul>
                                </li>
                                <li className='c-header__right--list--item c-header__right--list--desktop-only'>
                                    <Link id='gtm-header-bookshops' to='/info/audiobook-in-a-card' className='font-secondary font-uppercase letter-spacing-15 font-14'>Bookshops</Link>
                                </li>
                                <li className='c-header__right--list--item c-header__right--list--desktop-only'>
                                    <div className='c-header__right--item font-secondary font-uppercase letter-spacing-15 font-14'>About</div>
                                    <ul className='c-header__right--sub-list'>
                                        {menuLinkListHeader}
                                    </ul>
                                </li>
                                <li className='c-header__right--list--item c-header__right--list--desktop-only'>
                                    <Link id='gtm-header-subscription' to='/subscription' className='font-secondary font-uppercase letter-spacing-15 font-14'>Membership</Link>
                                </li>

                                <li onClick={() => this.handleSearchMenuOpen()} className='c-header__right--list--item c-header__right--list--search'>
                                    <i className='icon-search font-24 font-black'></i>
                                </li>
                                {!this.props.user ?
                                    <li onClick={() => this.handleShopOpen()} className='c-header__right--list--item'>
                                        <i className='icon-shopping-bag font-24 font-black'>
                                            {this.props.cart.length > 0 ? <span className="font-12 font-secondary">{this.props.cart.length}</span> : null}
                                        </i>
                                    </li> : 
                                        null}

                                {this.props.user ? (
                                    <>
                                        <li onClick={() => this.handleLibraryOpen()} className='c-header__right--list--item'>
                                            <i className='icon-library font-24 font-black'></i>
                                        </li>
                                        <li onClick={() => this.handleUserOpen()} className='c-header__right--list--item'>
                                            <i className='icon-account font-24 font-black'></i>
                                        </li>
                                        <li onClick={() => this.handleShopOpen()} className='c-header__right--list--item'>
                                            <i className='icon-shopping-bag font-24 font-black'>
                                                {this.props.cart.length > 0 ? <span className="font-12 font-secondary">{this.props.cart.length}</span> : null}
                                            </i>
                                        </li>
                                    </>
                                ) : (
                                    this.props.redemptionLayout ? null : 
                                        <>
                                            <li onClick={() => this.handleUserOpen()} className='c-header__right--list--item h-flex h-flex-middle'>
                                                <i className='icon-login font-10 font-black'></i>
                                            </li>
                                            <li className='c-header__right--list--item h-flex h-flex-middle'>
                                                <Link id='gtm-header-sign-up' to='/sign-up' className='sa-button-red sa-button-rounded sa-button-small letter-spacing-15'>JOIN</Link>
                                            </li>
                                        </>
                                )}
                            </ul>
                        </nav>
                        {/* *************** */}
                    </div>

                    {/* MOBILE NAVIGATION */}
                    {this.state.hasVScroll ? <div className='c-navigation-mobile-shadow'></div> : null}

                    <div className='c-navigation'>
                        <ul className={`c-navigation__mobile h-flex h-flex-between h-flex-middle ${!this.state.menuOpen && !this.state.searchMenuOpen && !this.state.libraryMenuOpen && !this.state.userMenuOpen && 'c-navigation__mobile--active'}`}>
                            <li onClick={() => this.handleMenuOpen()} className={`c-navigation__header--item ${this.state.menuOpen && 'c-navigation__header--item--active'}`}>
                                <i className='font-black font-24 icon-hamburger'></i>
                            </li>
                            <li onClick={() => this.handleSearchMenuOpen()} className={`c-navigation__header--item ${this.state.searchMenuOpen && 'c-navigation__header--item--active'}`}>
                                <i className='font-black font-24 icon-search'></i>
                            </li>
                            {this.props.user ? (
                                <>
                                    <li onClick={() => this.handleLibraryOpen()} className={`c-navigation__header--item ${this.state.libraryMenuOpen && 'c-navigation__header--item--active'}`}>
                                        <i className='font-black font-24 icon-library'></i>
                                    </li>
                                    <li onClick={() => this.handleUserOpen()} className={`c-navigation__header--item ${this.state.userMenuOpen && 'c-navigation__header--item--active'}`}>
                                        <i className='icon-account font-25 font-black'></i>
                                    </li>
                                </>
                            ) : (
                                this.props.redemptionLayout ? null : 
                                <>
                                    <li onClick={() => {this.handleUserOpen(); this.handleCloseModals()}} className={`c-navigation__header--item h-flex h-flex-middle`}>
                                        <i className='icon-login font-10 font-black'></i>
                                    </li>
                                    <li className={`c-navigation__header--item h-flex h-flex-middle`}>
                                         <Link id='gtm-header-sign-up' to='/sign-up' className='sa-button-red sa-button-rounded sa-button-small letter-spacing-15'>JOIN</Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                    {/* *************** */}

                    {/* NAVIGATION */}
                    <nav className='c-navigation'>
                        {/* BURGER MAIN NAVIGATION */}
                        <div className={`c-navigation__burger-navigation ${this.state.menuOpen ? 'menu-open' : ''}`}>
                            <div onClick={() => this.handleMenuOpen()} className='c-navigation__burger-navigation__burger-menu mb-32'>
                                <i className='font-24 font-black icon-close'></i>
                            </div>
                            <h3 className='font-12 font-400 font-uppercase font-secondary letter-spacing-15 mb-15'>AUDIOBOOKS</h3>
                            <div className='mb-21'>
                                <Link id='gtm-header-browse' className='c-btn c-btn__blue font-20 font-secondary' style={{ padding: '8px 17.36px 12px' }} to='/browse' onClick={this.handleMenuOpen}>
                                    Browse all
                                </Link>
                            </div>
                            <div className='mb-21'>
                                <Link id='gtm-header-editions' className='c-btn c-btn__brown font-20' to='/editions?_page=1&itemsPerPage=5' onClick={this.handleMenuOpen}>
                                    Spiracle <span className='font-italic'>editions</span>
                                </Link>
                            </div>
                            <div className='c-list c-list--ebooks mt-21 mb-21 c-list--collection h-flex h-flex-wrap gap-20'>
                                <Link id='gtm-header-collection' onClick={() => this.setState({ menuOpen: false })} className='c-btn c-btn__black c-btn__black--italic mr-7 font-20 font-secondary' to='/audiobooks?page=1&itemsPerPage=12'>
                                    Collection
                                </Link>
                                <Link id='gtm-header-current-highlights' onClick={() => this.setState({ menuOpen: false })} className='c-btn c-btn__ghost c-btn__black--italic mr-7 font-20 font-secondary sa-button-green-bg' to='/subscriber-selection?page=1&itemsPerPage=12'>
                                    Current <span className='font-italic'>Highlights</span>
                                </Link>
                            </div>
                            <div>
                                {menuCategories}
                            </div>

                            <h3 className='font-12 font-400 font-uppercase margin-reset font-secondary letter-spacing-15 mt-27'>MURMURATIONS</h3>
                            <ul className='c-list c-list--ebooks mt-21 mb-21 h-flex h-flex-wrap'>
                                {menuMurmurations}
                            </ul>

                            <h3 className='font-12 font-400 font-uppercase margin-reset font-secondary letter-spacing-15 mt-27 mb-21'>PARTNER WITH US</h3>
                            <Link id='gtm-header-bookshops' className='c-btn c-btn__ghost mr-7 mb-13 font-22 font-secondary font-italic' to='/info/audiobook-in-a-card'>Bookshops</Link>

                            <h3 className='font-12 font-400 font-uppercase margin-reset font-secondary letter-spacing-15 mt-27'>ABOUT</h3>
                            <ul className='c-list c-list--ebooks mt-21 mb-21 h-flex h-flex-wrap'>
                                {menuLinkList}
                            </ul>

                            <h3 className='font-12 font-400 font-uppercase margin-reset font-secondary letter-spacing-15 mt-27 mb-21'>Membership</h3>
                            <Link id='gtm-header-subscription' to='/subscription' className='c-btn c-btn__brown font-20 font-secondary' style={{ padding: '8px 17.36px 12px' }}>Membership plans & Gifts</Link>

                            <div className='mt-29'>
                                <Link id='gtm-header-terms-privacy' to='/info/terms-privacy-etc' className='font-12 font-secondary animated-underline'>Terms, Privacy, etc.</Link>
                            </div>
                        </div>
                        {/* *************** */}

                        {/* SEARCH MENU */}
                        <div className={`c-navigation__search-navigation ${this.state.searchMenuOpen ? 'menu-open' : ''}`}>
                            <ul className='c-navigation__header h-flex h-flex-between mb-20'>
                                <li onClick={() => this.handleSearchMenuOpen()} className='c-navigation__header--item'>
                                    <i className='icon-close font-24 font-black'></i>
                                </li>
                                <li onClick={() => this.handleShopOpen()} className='c-navigation__header--item'>
                                    <i className='icon-shopping-bag font-24 font-black'>
                                        {this.props.cart.length > 0 ? <span className="font-12 font-secondary">{this.props.cart.length}</span> : null}
                                    </i>
                                </li>
                            </ul>

                            <div className='c-navigation__search-navigation__input'>
                                <input type='text' placeholder='SEARCH' ref={this.searchInput} value={this.state.searchKeyword} onChange={(e) => this.handleSearch(e)}  />
                                {this.state.searchKeyword.length > 0 && 
                                    <i className='icon-close font-11 font-black' onClick={this.handleResetSearchKeyword}></i>}
                            </div>
                                                      
                            {this.state.isLoadingSearch && this.state.searchKeyword.length > 2 ? <SkeletonSearch /> : ((this.state.searchResults.booksTotal || this.state.searchResults.articlesTotal) && this.state.searchKeyword.length > 2) ? 
                                <div className='c-navigation__search-results'>
                                    <div className={`c-navigation__search-audiobooks ${(this.state.searchResults.booksTotal && this.state.searchResults.articlesTotal) ? 'mb-66' : 'mb-37'} ${this.state.searchResults.booksTotal > 4 && 'c-navigation__search-audiobooks--shadow'}`}>
                                        {this.state.searchResults.booksTotal > 0 && 
                                            <div className='font-12 letter-spacing-15 font-secondary font-uppercase ml-26 mt-25 mb-12'>
                                                {this.state.searchResults.booksTotal} result{this.state.searchResults.booksTotal > 1 && 's'}
                                            </div>}

                                        <Grid container spacing={2}>{searchBooks}</Grid>

                                        {this.state.searchResults.booksTotal > 4 && 
                                            <div className='h-flex h-flex-center c-navigation__search-audiobooks__show-filters'>
                                                <div id='gtm-show-all-books' onClick={() => this.handleShowAll('books')} className='show-filters'></div>
                                            </div>}
                                    </div>
                                    
                                    <div className={`c-navigation__search-articles ${this.state.searchResults.booksTotal >= 4 && this.state.searchResults.articlesTotal && 'mt-99'}`}>
                                        {this.state.searchResults.articlesTotal > 0 && 
                                            <div className='font-12 letter-spacing-15 font-secondary font-uppercase ml-26 mt-25 mb-12'>
                                                {this.state.searchResults.articlesTotal} result{this.state.searchResults.articlesTotal > 1 && 's'}
                                            </div>}

                                        {searchArticles}

                                        {this.state.searchResults.articlesTotal > 2 && 
                                            <div className='h-flex h-flex-center'>
                                                <div id='gtm-show-all-murmurations' onClick={() => this.handleShowAll('articles')} className='show-filters'></div>
                                            </div>
                                        }
                                    </div>
                                </div> : 
                                (this.state.searchResults.booksTotal === 0 && this.state.searchResults.articlesTotal === 0 && this.state.searchKeyword.length > 2) ? 
                                    <EmptyPage className='mt-12'>
                                        <p>Sorry, no results.</p>
                                        <p>Try again or visit our{" "}<Link id='gtm-header-collection' to="/audiobooks" className="font-secondary">collection</Link></p>
                                    </EmptyPage> : (
                                    <>
                                        <div className='c-list c-list--ebooks mt-18 mb-28 h-flex h-flex-wrap ml-16 mr-16'>
                                            {searchMenuListGroupOne}
                                        </div>

                                        <h3 className='font-12 font-400 font-uppercase font-secondary letter-spacing-15 ml-23 mr-16'>FROM SPIRACLE</h3>
                                        <div className='c-list c-list--ebooks h-flex h-flex-wrap ml-16 mr-16'>
                                            <Link id='gtm-header-editions' className='c-btn c-btn__brown font-22 mr-7' to='/editions?_page=1&itemsPerPage=5' onClick={this.handleSearchMenuOpen} style={{alignSelf: 'flex-end'}}>
                                                Spiracle <span className='font-italic'>editions</span>
                                            </Link>
                                            {searchMenuListGroupTwo}
                                        </div>
                                    </>
                            )}
                        </div>
                        {/* *************** */}

                        {/* LIBRARY MENU */}
                        <div onScroll={(e) => this.handleLoadMoreLibrary(e)} className={`c-navigation__library-navigation ${this.state.libraryMenuOpen ? 'menu-open' : ''}`}>
                            <ul className='c-navigation__header h-flex h-flex-between mb-42'>
                                <li onClick={() => this.handleLibraryOpen()} className='c-navigation__header--item'>
                                    <i className='icon-close font-24 font-black'></i>
                                </li>
                                <li onClick={() => this.handleShopOpen()} className='c-navigation__header--item'>
                                    <i className='icon-shopping-bag font-24 font-black'>
                                        {this.props.cart.length > 0 ? <span className="font-12 font-secondary">{this.props.cart.length}</span> : null}
                                    </i>
                                </li>
                            </ul>

                            <h3 className='font-uppercase font-12 font-secondary ml-24 mb-29 letter-spacing-15'>My library</h3>
                            {libraryItems}
                            {this.state.isLoadMoreLibrary && <SkeletonOrderItem noTop items={3} />}
                            {!this.props.library.length || !this.props.cart ? <EmptyOrdersPage pageName="library" inDropdown library/> : null}
                        </div>
                        {/* *************** */}

                        {/* USER MENU */}
                        <div className={`c-navigation__user-navigation ${this.state.userMenuOpen ? 'menu-open' : ''}`}>
                            <ul className='c-navigation__header h-flex h-flex-between mb-27'>
                                <li onClick={() => this.handleUserOpen()} className='c-navigation__header--item'>
                                    <i className='icon-close'></i>
                                </li>
                                <li onClick={() => this.handleShopOpen()} className='c-navigation__header--item'>
                                    <i className='icon-shopping-bag font-24 font-black'>
                                        {this.props.cart.length > 0 ? <span className="font-12 font-secondary">{this.props.cart.length}</span> : null}
                                    </i>
                                </li>
                            </ul>

                            <h3 className='font-12 font-400 font-uppercase margin-reset mb-27 font-secondary letter-spacing-15'>My account</h3>

                            <ul className='c-list c-list--none mb-35'>
                                <li className='mb-27'>
                                    <Link id='gtm-header-library' onClick={(e) => {e.preventDefault(); this.handleLibraryOpen()}} className='c-btn c-btn__menu-link display-inline-block font-secondary font-16 h-flex h-flex-between h-flex-align-center' to='/#'>
                                        Library <i className='icon-arrow'></i>
                                    </Link>
                                </li>
                                <li className='mb-27'>
                                    <Link
                                        id='gtm-header-settings'
                                        onClick={() => this.handleCloseLoginModal()}
                                        className='c-btn c-btn__menu-link display-inline-block font-secondary font-16 h-flex h-flex-between h-flex-align-center'
                                        to='/settings'>
                                        Settings <i className='icon-arrow'></i>
                                    </Link>
                                </li>
                                <li className='mb-27'>
                                    <Link
                                        id='gtm-header-subscription'
                                        onClick={() => this.handleCloseLoginModal()}
                                        className='c-btn c-btn__menu-link display-inline-block font-secondary font-16 h-flex h-flex-between h-flex-align-center'
                                        to='/subscription-details'>
                                        Subscriptions <i className='icon-arrow'></i>
                                    </Link>
                                </li>
                                <li className='mb-27'>
                                    <Link
                                        id='gtm-header-change-password'
                                        onClick={() => this.handleCloseLoginModal()}
                                        className='c-btn c-btn__menu-link display-inline-block font-secondary font-16 h-flex h-flex-between h-flex-align-center'
                                        to='/change-password'>
                                        Change password <i className='icon-arrow'></i>
                                    </Link>
                                </li>
                                <li className='mb-27'>
                                    <Link 
                                        id='gtm-header-order-history'
                                        onClick={() => this.handleCloseLoginModal()}
                                        className='c-btn c-btn__menu-link display-inline-block font-secondary font-16 h-flex h-flex-between h-flex-align-center' 
                                        to='/orders'>
                                        Order history <i className='icon-arrow'></i>
                                    </Link>
                                </li>
                            </ul>
                            <button id='gtm-logout' className='c-btn c-btn__ghost c-btn__ghost--square text-center font-uppercase full-width' style={{width: 'calc(100% + 7px)', marginLeft: '-5px'}} onClick={this.handleLogout}>
                                Log out
                            </button>
                        </div>
                        {/* *************** */}

                        {/* SHOP MENU */}
                        <div className={`c-navigation__shop-navigation ${this.state.shopMenuOpen ? 'menu-open' : ''}`}>
                            <ul className='c-navigation__header h-flex h-flex-between mb-27'>
                                <li onClick={() => this.handleShopOpen()} className='c-navigation__header--item'>
                                    <i className='icon-close'></i>
                                </li>
                                <li onClick={() => this.handleShopOpen()} className='c-navigation__header--item'>
                                    <i className='icon-shopping-bag font-24 font-black'>
                                        {this.props.cart.length > 0 ? <span className="font-12 font-secondary">{this.props.cart.length}</span> : null}
                                    </i>
                                </li>
                            </ul>

                            {this.props.cart.length ?  
                                <>                            
                                    <ShoppingBagItems 
                                        cartItemsList={true}
                                        onClick={this.handleShopOpen}
                                    />
                                    <Link id='gtm-header-shopping-bag' to="/shopping-bag" onClick={this.handleShopOpen} className="c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-14 letter-spacing-15 font-uppercase text-center">Checkout</Link>
                                </>
                                    
                                :
                                <EmptyOrdersPage pageName="bag" inDropdown bag/>
                            }      
                        </div>
                        {/* *************** */}
                    </nav>
                </header>
                <Auth openAuth={this.state.openAuth} closeLoginModal={() => this.handleCloseLoginModal()} />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    cart: state.cartReducer.cart,
    library: state.libraryReducer.library,
    libraryTotalItems: state.libraryReducer.libraryTotalItems
});

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(userActionTypes.onLogout()),
        onClearCart: () => dispatch(cartActionTypes.onClearCart()),
        onLoadMoreLibraryItems: (libraryItems, total) => dispatch(libraryActionTypes.onLoadMoreLibraryItems(libraryItems, total)),
        onRemoveLibrary: () => dispatch(libraryActionTypes.onRemoveLibrary()),
        onRemoveBook: () => dispatch(bookActionTypes.onRemoveBook()),
    };
};

const headerWithRouter = withRouter(Header);

export default connect(mapStateToProps, mapDispatchToProps)(headerWithRouter);
